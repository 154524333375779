/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Profile } from '@prisma/client';
import { toast } from 'vue-sonner';
import { ErrorCode } from '~/enum/error-code.enum';
import type { Language } from '~/enum/language.enum';
import { ResultTextType } from '~/enum/result-text-type.enum';

export const useMigrateUser = () => {
  const baseCompanyId = ref('');
  const scanId = ref('');
  const targetCompanyId = ref('');
  const { t, locale } = useI18n();
  const { user } = storeToRefs(useUserStore());

  const migrate = async () => {
    if (!user) {
      return;
    }
    try {
      const companyProfile = localStorage.getItem('companyProfile');
      let language = locale.value.toUpperCase() as Language;

      if (companyProfile) {
        const parsed = JSON.parse(companyProfile) as Profile;
        if (parsed.language) {
          language = parsed.language as Language;
        }
        const profile = await useProfileStore().postProfile(
          parsed,
          true,
          language
        );
        baseCompanyId.value = profile.id;
      }
      const targetProfile = localStorage.getItem('targetProfile');

      if (targetProfile) {
        const parsed = JSON.parse(targetProfile) as Profile;
        const profile = await useProfileStore().postProfile(
          parsed,
          false,
          language
        );

        targetCompanyId.value = profile.id;
      }

      if (!baseCompanyId.value || !targetCompanyId.value) {
        return;
      }

      await createScan(language);

      if (!scanId.value) {
        return;
      }

      const compatibilityReason = localStorage.getItem('compatibility');
      if (compatibilityReason) {
        await useCompanyStore().createResultText({
          name: 'compatibility',
          resultTextType: ResultTextType.COMPATIBILITY,
          scanId: scanId.value,
          text: JSON.parse(compatibilityReason),
          language,
        });
      }

      const salesText = localStorage.getItem('salesText');
      if (salesText) {
        await useCompanyStore().createResultText({
          name: 'Verkaufsargumentation',
          resultTextType: ResultTextType.WHATWHY,
          scanId: scanId.value,
          text: JSON.parse(salesText),
          language,
        });
      }

      const letter = localStorage.getItem('letter');
      if (letter) {
        await useCompanyStore().createResultText({
          name: 'Salestext',
          resultTextType: ResultTextType.SALESTEXT,
          scanId: scanId.value,
          text: JSON.parse(letter),
          language,
        });
      }
      await useCompanyStore().getOwnProfiles();
    } catch (e: any) {
      toast.warning(t(e.data?.statusMessage ?? ErrorCode.GENERIC));
      clearError();
    }

    cleanUpStorage();
  };

  const createScan = async (language: Language) => {
    const scan = await useScanStore().createScan({
      companyProfileId: baseCompanyId.value,
      targetProfileId: targetCompanyId.value,
      language,
    });

    scanId.value = scan.id;
  };

  const cleanUpStorage = () => {
    const keys = ['companyProfile', 'targetProfile', 'letter', 'salesText'];
    keys.forEach((key) => {
      localStorage.removeItem(key);
    });
  };
  return { migrate };
};
