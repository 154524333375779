<script setup lang="ts">
import { cn } from '@/lib/utils';
import { cva } from 'class-variance-authority';
import {
  DialogContent,
  DialogOverlay,
  DialogPortal,
  useEmitAsProps,
  type DialogContentEmits,
  type DialogContentProps,
} from 'radix-vue';

interface SheetContentProps extends DialogContentProps {
  side?: 'left' | 'right' | 'top' | 'bottom';
  class?: string;
}

const props = defineProps<SheetContentProps>();

const emits = defineEmits<DialogContentEmits & { close: [] }>();

const emitsAsProps = useEmitAsProps(emits);

const sheetVariants = cva(
  'fixed z-[200000] gap-4 bg-background shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500',
  {
    variants: {
      side: {
        top: 'inset-x-0 top-0 border-b  data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top',
        bottom:
          'inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom',
        left: 'inset-y-0 left-0 h-full w-3/4 border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm',
        right:
          'inset-y-0 right-0 h-full w-3/4 data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm',
      },
    },
    defaultVariants: {
      side: 'right',
    },
  }
);
</script>

<template>
  <DialogPortal>
    <DialogOverlay
      class="fixed inset-0 z-[200000] bg-background/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
      @click="emits('close')"
    />
    <DialogContent
      :class="cn(sheetVariants({ side: props.side }), props.class)"
      v-bind="{ ...props, ...emitsAsProps }"
    >
      <slot />
    </DialogContent>
  </DialogPortal>
</template>
