<template>
  <div
    class="relative flex max-h-[100dvh] min-h-[100dvh] overflow-hidden bg-iq-grey-dark"
  >
    <TheCreateOrgDialog
      v-if="!user?.organizationId && user?.emailVerified"
      @organization:created="onOrgCreated()"
    />

    <TheUserVerificationDialog v-if="!user?.emailVerified" />
    <div
      class="lg:hidden h-11 w-full bg-iq-grey-dark flex items-center justify-between p-4 fixed top-0 left-0 right-0 z-50"
    >
      <AppCredits
        v-if="subscription?.tier?.level !== TierLevel.FREE || credits > 0"
        class="absolute z-30 top-2 mt-0.5 left-4 lg:hidden"
        :value="credits"
      />
      <div
        class="fixed right-0 z-30 flex items-center p-4 lg:hidden"
        @click="isOpen = !isOpen"
      >
        <button>
          <Icon name="heroicons:bars-3" class="w-8 h-8" />
        </button>
      </div>
    </div>
    <TheMenu class="hidden w-106 lg:flex" />
    <TheSheet :is-open="isOpen" @close:sheet="isOpen = false">
      <template #content> <TheMenu @close:sheet="isOpen = false" /> </template
    ></TheSheet>

    <div class="w-full h-full overflow-hidden max-h-[100dvh] relative">
      <TheLayerBlockUI
        v-if="unpaidInvoices?.hasUnpaidInvoices"
        :links="unpaidInvoices.invoiceUrls"
        class="absolute top-0 left-0 z-[10000]"
      />
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { TierLevel } from '@/enum/tier-level.enum';

useHead({
  bodyAttrs: {
    class: 'dark',
  },
});

const isOpen = ref(false);
const userStore = useUserStore();
const organizationStore = useOrganizationStore();
const { user, unpaidInvoices } = toRefs(userStore);
const { subscription } = storeToRefs(organizationStore);

const userMigrate = useMigrateUser();

const credits = computed(() => {
  const credits = subscription.value?.credits ?? 0;
  const creditsExtra = subscription.value?.creditsExtra ?? 0;
  const creditsReserved = subscription.value?.creditsReserved ?? 0;

  return credits + creditsExtra - creditsReserved;
});
const onOrgCreated = async () => {
  const priceId = userStore.priceIdForRedirect;

  //user comes from buying subscription from landingpage
  if (priceId) {
    return await navigateTo({
      path: '/checkout',
      query: {
        priceId: priceId,
        checkoutIdentifier: 'subscription',
      },
    });
  }

  //maybe user has started scan on landingpage and comes from there
  await userMigrate.migrate();
};
</script>
