<template>
  <div
    class="fixed top-0 left-0 z-50 flex items-center justify-center w-full min-h-[100dvh] backdrop-blur-sm"
  >
    <UiCard class="relative w-full max-w-lg m-4">
      <UiCardHeader class="items-center justify-center">
        <UiCardTitle class="tracking-wide text-semibold-24">
          {{ t('ui.cardTitle') }}
        </UiCardTitle>
        <UiCardDescription>{{ t('ui.cardDescription') }}</UiCardDescription>
      </UiCardHeader>
      <form class="flex flex-col p-8 w-full" @submit="onSubmit">
        <UiCardContent class="flex flex-col w-full px-12">
          <FormField
            v-for="(input, i) in inputs"
            :key="i"
            v-slot="{ componentField }"
            :name="input.fieldName"
          >
            <UiFormItem class="mt-4">
              <UiFormLabel class="text-regular-16" :for="input.fieldName">
                {{ t(`labels.${input.fieldName}`) }}
              </UiFormLabel>
              <UiFormControl>
                <UiInput
                  v-if="input.type === 'text'"
                  :id="input.fieldName"
                  :type="input.fieldName"
                  v-bind="componentField"
                />
              </UiFormControl>
              <UiFormMessage />
            </UiFormItem>
          </FormField>
        </UiCardContent>
        <UiCardFooter class="flex flex-col justify-end w-full px-12 mt-4">
          <span
            v-if="errorMessage"
            class="text-regular-14 !text-accent w-full items-center"
          >
            {{ errorMessage }}
          </span>
          <UiButton class="iq-button-success">
            <TheSpinner v-if="isLoading" />
            <span v-if="!isLoading" class="tracking-iq-wide">
              {{ t('ui.saveButton') }}
            </span>
          </UiButton>
        </UiCardFooter>
      </form>
    </UiCard>
  </div>
</template>

<script setup lang="ts">
import { FormField } from '@/components/ui/form';
import type { Organization, Subscription } from '@prisma/client';
import type { User } from 'lucia';

import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import * as z from 'zod';
import type { SubscriptionInclude } from '~/interfaces/subscription-include.interface';
const userStore = useUserStore();
const { t } = useI18n();
const organizationStore = useOrganizationStore();
const errorMessage = ref('');
const emit = defineEmits<{
  'organization:created': [];
  'close:popup': [];
}>();

const isLoading = ref(false);

const inputs = [
  //
  { fieldName: 'companyName', label: 'companyName', type: 'text' },
];

const formSchema = toTypedSchema(
  z.object({
    companyName: z
      .string({ required_error: 'Firmenname ist erforderlich' })
      .min(2, 'Firmenname sollte mindestens 2 Zeichen haben.'),
  })
);
const { handleSubmit } = useForm({
  validationSchema: formSchema,
});

const onSubmit = handleSubmit(async (values) => {
  isLoading.value = true;
  const { companyName } = values;
  const language = 'DE'; // Set default or use a dynamic value

  try {
    const data = await $fetch<{
      user: User;
      subscription: Subscription;
      // settings: Settings;
      organization: Organization;
    }>('api/organizations', {
      method: 'POST',
      body: {
        companyName,
        language,
      },
      retry: false,
    });

    if (data.organization?.id && userStore.user) {
      userStore.user.organizationId = data.organization.id;
      organizationStore.subscription = data.subscription as SubscriptionInclude;
      organizationStore.organization = data.organization;

      emit('organization:created');
    }
  } catch {
    errorMessage.value = 'Etwas ist schief gegangen. Bitte versuche es erneut';
    return;
  } finally {
    isLoading.value = false;
  }
});
</script>

<i18n lang="json">
{
  "en": {
    "ui": {
      "cardTitle": "Your Company Data",
      "cardDescription": "Please enter your company name",
      "saveButton": "Save"
    },
    "labels": {
      "companyName": "Company Name",
      "language": "Select Language"
    },
    "errors": {
      "minCompanyName": "The company name must be at least 2 characters long.",
      "generalError": "Something went wrong. Please try again."
    }
  },
  "de": {
    "ui": {
      "cardTitle": "Daten deiner Firma",
      "cardDescription": "Bitte gib deinen Firmennamen an",
      "saveButton": "Speichern"
    },
    "labels": {
      "companyName": "Firmenname",
      "language": "Sprache wählen"
    },
    "errors": {
      "minCompanyName": "Der Firmenname muss mindestens 2 Zeichen lang sein.",
      "generalError": "Etwas ist schief gegangen. Bitte versuche es erneut."
    }
  }
}
</i18n>
