<template>
  <div
    ref="menu"
    class="flex flex-col min-h-[100%] max-h-[100%] bg-[#00000099] [@media_(max-height:_740px)]:overflow-y-auto"
  >
    <NuxtImg
      src="/images/b2bscan-horizontal.svg"
      width="300px"
      height="100px"
      class="p-8"
      placeholder
    />

    <div
      class="flex flex-col flex-1 px-8 link-wrapper place-content-center [@media_(max-height:900px)]:!pr-32"
    >
      <NuxtLink
        v-for="(link, index) in links"
        :key="index"
        class="pb-2 font-outfit text-iq-white tracking-normal text-iq42 font-100 iq-hover md:text-thin-66 xl:text-thin-70 mb-4 lg:mb-8 [@media_(max-height:900px)]:text-thin-42"
        :class="{
          'md:text-light-66 xl:text-light-72 !text-light-42 border-b-2 mb-4 border-iq-red':
            isActiveRoute(link.route),
        }"
        :to="link.route"
        @click="emit('close:sheet')"
      >
        {{ t(`links.${link.name}.label`) }}
      </NuxtLink>
    </div>
    <div class="flex flex-col pl-4 lg:pl-0">
      <NuxtLink
        class="flex flex-row w-min p-2 lg:p-4 lg:pl-8 lg:pb-2 iq-hover"
        to="/userprofile"
        @click="emit('close:sheet')"
      >
        <Icon name="heroicons:user-circle" class="h-7 w-7" />
        <div class="ml-2 lg:ml-4 text-regular-18">{{ t('profile.label') }}</div>
      </NuxtLink>

      <NuxtLink
        class="flex flex-row w-min p-2 lg:p-4 lg:pl-8 lg:pb-2 iq-hover"
        to="/organization"
        @click="emit('close:sheet')"
      >
        <Icon name="heroicons:users" class="h-7 w-7" />
        <div class="ml-2 lg:ml-4 text-regular-18">{{ t('team.label') }}</div>
      </NuxtLink>

      <AppLogout />
    </div>

    <div
      class="flex justify-between lg:justify-around px-4 py-2 lg:px-8 lg:py-4 border-solid border-t-1 border-iq-white"
    >
      <NuxtLink
        v-for="(link, index) in regulationLinks"
        :key="index"
        :to="link.route"
        @click="$emit('close:sheet')"
      >
        <div
          class="text-bold-12 iq-hover tracking-[3.6px]"
          :class="{ '!text-light-12': isActiveRoute(link.route) }"
        >
          {{ t(`regulations.${link.name}.label`) }}
        </div>
      </NuxtLink>
      <TheLanguageSwitcher />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
const { t } = useI18n({ useScope: 'local' });

const emit = defineEmits(['close:sheet']);

const links = [
  { name: 'scan', route: '/scan' },
  { name: 'scanprofile', route: '/scanprofile' },
  { name: 'archiv', route: '/archive' },
  { name: 'leads', route: '/leads' },
  { name: 'credits', route: '/credits' },
];

function isActiveRoute(route: string) {
  return route === useRoute().path;
}

const regulationLinks = [
  { name: 'IMPRESSUM', route: '/imprint' },
  { name: 'DATENSCHUTZ & AGB', route: '/privacy' },
];
</script>

<style scoped lang="scss">
.link-wrapper {
  @apply pt-[0%];

  @media screen and (min-height: 850px) {
    @apply pt-[7%];
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "links": {
      "scan": { "label": "scan" },
      "scanprofile": { "label": "scanprofiles" },
      "archiv": { "label": "archive" },
      "leads": { "label": "leads" },
      "credits": { "label": "credits" }
    },
    "profile": { "label": "Profile" },
    "team": { "label": "Team" },
    "regulations": {
      "IMPRESSUM": { "label": "IMPRINT" },
      "DATENSCHUTZ & AGB": { "label": "PRIVACY & TERMS" }
    }
  },
  "de": {
    "links": {
      "scan": { "label": "scan" },
      "scanprofile": { "label": "scanprofiles" },
      "archiv": { "label": "archive" },
      "leads": { "label": "leads" },
      "credits": { "label": "credits" }
    },
    "profile": { "label": "Profil" },
    "team": { "label": "Team" },
    "regulations": {
      "IMPRESSUM": { "label": "IMPRESSUM" },
      "DATENSCHUTZ & AGB": { "label": "DATENSCHUTZ & AGB" }
    }
  }
}
</i18n>
