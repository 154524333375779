<template>
  <div
    class="flex flex-row w-min p-2 cursor-pointer lg:pl-8 lg:p-4 iq-hover"
    @click="logout"
  >
    <Icon
      name="heroicons:arrow-right-end-on-rectangle-20-solid"
      class="w-7 h-7"
    />
    <div class="ml-2 lg:ml-4 text-regular-18">{{ t('logout') }}</div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n({
  useScope: 'local',
});
const logout = async () => {
  try {
    await $fetch('/api/auth/logout', {
      method: 'GET',
      redirect: 'manual',
      retry: false,
    });
    clearWrittenData();
    return await navigateTo('/login');
  } catch (e) {
    console.error(e);
  }
};
</script>
<i18n lang="json">
{
  "de": {
    "logout": "AUSLOGGEN"
  },
  "en": {
    "logout": "LOGOUT"
  }
}
</i18n>
