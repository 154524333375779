<template>
  <UiSheet :open="props.isOpen">
    <UiSheetTrigger class="flex flex-col">
      <slot name="trigger" />
    </UiSheetTrigger>

    <UiSheetContent @close="emit('close:sheet')">
      <UiSheetHeader class="relative">
        <UiSheetTitle />
        <UiSheetDescription /><button
          class="absolute z-30 top-4 right-4 lg:hidden"
          @click="emit('close:sheet')"
        >
          <Icon name="heroicons:x-mark-solid" class="w-6 h-6" /></button
      ></UiSheetHeader>
      <slot name="content" />
    </UiSheetContent>
  </UiSheet>
</template>
<script lang="ts" setup>
const props = defineProps<{ isOpen: boolean }>();
const emit = defineEmits(['close:sheet']);
</script>
