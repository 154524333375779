<template>
  <div
    class="fixed top-0 left-0 z-50 flex items-center justify-center w-full min-h-[100dvh] backdrop-blur-sm"
  >
    <UiCard class="relative w-full max-w-md p-4 m-4">
      <UiCardHeader class="items-center justify-center">
        <UiCardTitle class="tracking-wide text-semibold-24">{{
          t('card.title')
        }}</UiCardTitle>
        <UiCardDescription
          class="flex flex-col items-center justify-center text-regular-18"
        >
          <span class="text-regular-12">{{ t('card.description[0]') }} </span>
          <span>{{ t('card.description[1]') }}</span>
        </UiCardDescription>
      </UiCardHeader>
      <UiCardContent class="flex flex-col gap-2">
        <div v-if="message" class="w-full text-center text-regular-14">
          {{ message }}
        </div>

        <UiLabel for="code" class="mt-2 sr-only">code</UiLabel>
        <UiInput id="code" v-model="code" name="code" />

        <div class="text-regular-14 !text-accent underline py-1 mb-2 w-full">
          <a class="hover:cursor-pointer" @click="resendCode()">
            {{ t('card.resendCode') }}</a
          >
        </div>
        <UiButton
          class="w-full iq-button-success"
          @click="sendVerificationCode()"
        >
          <TheSpinner v-if="isLoading" />
          <span v-if="!isLoading" class="tracking-iq-wide">
            {{ t('button.verify') }}
          </span>
        </UiButton>
      </UiCardContent>
      <UiCardFooter class="flex w-full" />
    </UiCard>
  </div>
</template>

<script setup lang="ts">
import type { Session } from 'lucia';
const { t } = useI18n({
  useScope: 'local',
});
const code = ref('');
const message = ref('');
const isLoading = ref(false);
const userStore = useUserStore();

const emit = defineEmits<{
  'user:verified': [];
  'close:popup': [];
}>();

async function sendVerificationCode() {
  isLoading.value = true;
  const { data, error } = await useFetch<Session>('/api/auth/verify', {
    method: 'POST',
    body: {
      code: code.value,
    },
    redirect: 'manual',
    retry: false,
  });

  if (!error.value && data.value && userStore.user) {
    userStore.user.emailVerified = data.value.user.emailVerified;
    emit('user:verified');
    return;
  }

  switch (error.value?.statusCode) {
    case 401:
      isLoading.value = false;
      message.value = t('errors.401');
      break;
    case 403:
      isLoading.value = false;
      message.value = t('errors.403');
      break;
    default:
      isLoading.value = false;
      message.value = t('errors.default');
      break;
  }
}

async function resendCode() {
  try {
    const { success } = await $fetch('/api/auth/create-verification', {
      method: 'POST',
      retry: false,
    });

    if (success) {
      message.value = t('success');
    }
  } catch (error) {
    console.error(error);
    message.value = t('errors.default');
  }
}
</script>

<style scoped></style>

<i18n lang="json">
{
  "de": {
    "button": {
      "verify": "VERIFIZIEREN"
    },
    "errors": {
      "401": "Der eingegebene Code ist nicht korrekt.",
      "403": "Der eingegebene Code ist bereits abgelaufen, bitte fordere einen neuen Code an.",
      "default": "Es ist ein Fehler aufgetreten. Bitte probiere es erneut."
    },

    "card": {
      "title": "Verifizierung",
      "description": [
        "Wir haben einen Code an deine E-Mail-Adresse gesendet.",
        "Bitte gebe den 6-Stelligen Code ein"
      ],
      "resendCode": "Code erneut senden"
    },
    "success": "Ein Verifizierungscode wurde an deine E-Mail Adresse gesendet."
  },
  "en": {
    "button": {
      "verify": "VERIFY"
    },
    "errors": {
      "401": "The entered code is incorrect.",
      "403": "The entered code has already expired, please request a new code.",
      "default": "An error has occurred. Please try again."
    },
    "card": {
      "title": "Verification",
      "description": [
        "We have sent a code to your email address.",
        "Please enter the 6-digit code"
      ],
      "resendCode": "Resend code"
    },
    "success": "A verification code has been sent to your email address."
  }
}
</i18n>
