<template>
  <div
    class="items-center content-center w-full h-full p-12 bg-iq-black/95 overflow-y-auto"
  >
    <div
      class="relative z-50 flex flex-col justify-center text-center text-regular-26"
    >
      {{ t('outstandingPayments') }}
      <br />
      <span class="mt-4">
        {{ t('settleClaims') }}
      </span>
    </div>
    <div class="flex flex-col items-center gap-4 mt-12">
      <a
        v-for="(link, index) in props.links"
        :key="index"
        :href="link"
        target="_blank"
        class="!text-iq-red underline text-regular-20"
        >{{ t('openInvoice', { index: index + 1 }) }}</a
      >
    </div>
    <div class="flex flex-col justify-center text-center text-regular-22 mt-4">
      {{ t('invoicesWithoutDue') }}
    </div>
    <div class="flex items-center justify-center mt-8">
      <a
        class="items-center mt-8 underline text-regular-18 iq-hover"
        @click="showContactFormular = true"
      >
        {{ t('contactSupport') }}
      </a>
    </div>
    <TheDialogSupport
      :dialog-type="ContactRequestType.INVOICE"
      :open="showContactFormular"
      @modal:close="showContactFormular = false"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { ContactRequestType } from '~/enum/contact-request-type.enum';
const { t } = useI18n({ useScope: 'local' });

const props = defineProps<{
  links: string[];
}>();

const showContactFormular = ref(false);
</script>

<i18n lang="json">
{
  "en": {
    "outstandingPayments": "You have outstanding payments despite repeated requests.",
    "settleClaims": "Please settle the claims to continue using the app.",
    "openInvoice": "{index}. Open Invoice",
    "contactSupport": "Use the form to contact us, and we’ll assist you with any invoice-related issues.",
    "invoicesWithoutDue": "You can find your outstanding invoices under Credits. Please pay these on time to avoid future blocks"
  },
  "de": {
    "outstandingPayments": "Du hast ausstehende Zahlungen trotz wiederholter Aufforderung nicht getätigt.",
    "settleClaims": "Bitte begleiche die Forderungen, um die App weiter nutzen zu können.",
    "openInvoice": "{index}. Rechnung öffnen",
    "contactSupport": "Falls du Fragen hast, wende dich an unseren Support.",
    "invoicesWithoutDue": "Deine noch nicht fälligen offenen Rechnungen findest du unter Credits. Bitte bezahle diese rechtzeitig, um zukünftige Sperrungen zu vermeiden"
  }
}
</i18n>
